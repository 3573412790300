:root {
  --lightGray: #a1a5b7;
  --darkGray: #7e8299;
}
.orderDetailsPage {
  display: flex;
  font-family: "Poppins", sans-serif;
}
.orderDetailsMain {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background: #fcfcfc;
}

.ordersMainSecComponent2 {
  background: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 7px;
  padding: 20px;
}

.ordersMainSecComponent1 {
  background: #ebecf1;
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.ordersMainSecComponent3 {
  background: #ebecf1;
  display: flex;
  gap: 20px;
  justify-content: space-between;
}
.squareComponent {
  width: 47%;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background: white;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.orderDetailsMainTop {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #ebecf1;
}
.ordersMainSec {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.squareComponentTitle {
  text-align: start;
  font-size: 20px;
  line-height: 23px;
  font-weight: 600;
}

.squareComponentContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.listItem {
  padding-top: 10px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.1px solid #f1f1f3;
}

.listItemLeft {
  text-align: start;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-weight: 500;
  color: var(--lightGray);
}
.listItemLeft svg {
  margin-top: 3px;
}

.listItemRight {
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: var(--darkGray);
}

.squareComponentContent2 {
  display: flex;
  gap: 20px;
  flex-direction: row;
}

.squareComponentConLeft {
  text-align: start;
}

.squareComponentTable {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.tableRow {
  text-align: start;
  padding-bottom: 20px;
  padding-top: 10px;
}

.tableRow2 {
  font-size: 14px;
  font-weight: 500;
  color: var(--darkGray) !important;
  line-height: 21px;
}
