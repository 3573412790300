.tooltip {
    position: absolute;
    background-color: #fff;
    color: #333333c8;
    text-align: center;
    padding: 5px 10px;
    border-radius: 5px;
    bottom: 100%;
    left: 60%;
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px;
    width: 20rem;
  }
  
  .tooltip::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #ffffffc8 transparent transparent transparent;
    /* width: 100px; */
  }

  .tooltip-container:hover .tooltip {
    cursor: pointer;
    visibility: visible;
    opacity: 1;
  }