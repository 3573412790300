.homePage {
  display: flex;
  font-family: "Poppins", sans-serif;
}
.homeMain {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background: #ebecf1;
}

.homePageMain {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  opacity: 0; /* Initially hidden */
  animation: fadeInUp 1.5s ease-out forwards; /* Animation definition */
}


@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(-50px); /* Move up initially */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Move to the original position */
  }
}

.homeMainTop {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media screen and (min-width: 200px) and (max-width: 380px){
  .vertical-timeline::before{
    width: 0px ;
  
  }

  .vertical-timeline-element-content{
    margin-bottom: 10px !important;
  }
}

.homePageComponent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  background: #ebecf1;
}

.homePageSquare {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: white;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  width: 175px;
}

.homePageSquareContent {
  font-size: 28px;

  line-height: 39px;
  font-weight: 600;
}

.bannerbtn {
  position: fixed;
  top: 80%;
  right: 20px; /* Adjust as needed */
  transform: translateY(-50%);
  z-index: 1000; /* Ensure it overlaps other components */
}
.bannerbtn button a{
  color: #ebecf1;
  text-decoration: none;
}

.bannerbtn button {
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  border-radius: 50%;
  background-color: #007bff; /* Adjust color as needed */
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px; /* Adjust as needed */
  cursor: pointer;
}
