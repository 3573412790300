.navigationBox {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 22%;
  height: fit-content;
  position: fixed;
  top:10%;
  background: #fcfcfc;
  border-radius: 3%;
 
}

.navigationBoxTop {
  padding-top: 30px;
  background-color: white;
  display: flex;
  padding-top: 20px;
  border-radius: 10px;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 30px;
  padding-left: 30px;
}

.navigationBoxBottom {
  background: white;
  border-radius: 10px;
  padding-bottom: 30px;
  /* padding-top: 20px; */
  padding-left: 30px;
}
.navBoxTitle {
  color: black;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
}

.navigationList {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.navigationListEle {
  display: flex;
  gap: 15px;
}

.navigationListEle div {
  font-size: 16px;
  color: #3f4254;
  margin-top: 2px;
  font-weight: 500;
  line-height: 22px;
}

.navigationListEle div:hover {
  font-size: 16px;
  color: #009ef7;
  cursor: pointer;
  margin-top: 2px;
  font-weight: 500;
  line-height: 22px;
}

.navigationListEle svg {
  margin-top: 5px;
  color: #3f4254;
}

.statusInstruction {
  font-size: 12px;
  font-weight: 400;
  color: #a1a5b7;
  text-align: left;
}
