.signInPage {
  /* font-family: Inter, 'Helvetica', sans-serif; */
  font-family: "Poppins", sans-serif;
  background-image: linear-gradient(to right, #50b5e7, #2ebf91);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.signInPopUp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  align-items: center;
  padding-top: 140px;
  padding-bottom: 140px;
  padding-left: 85px;
  padding-right: 85px;
  border-radius: 15px;
  background: white;
}

.ReactFlagsSelect-module_flagsSelect__2pfa2 {
  margin-top: 5px;
}

.signInTitle {
  width: 100%;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 30px;
  color: #181c32;
}
.inputHolder {
  width: 100%;
}

.inputHolder input {
  width: 100%;
  border: 1px solid #e1e3ea;
  border-radius: 7px;
  min-height: 50px;
  padding-left: 15px;
}

.inputHolder input::placeholder {
  /* font-family: "Poppins", sans-serif; */
  font-family: "Poppins", sans-serif;
  color: #5e6278;
  font-weight: 500;
}

.agreeButton {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 20px;
}

.agreeButton input {
  border: 1px solid #e1e3ea;
  height: 20px;
  width: 20px;
  border-radius: 6px;
}

.agreeButton div {
  margin-top: 3px;
  color: #5e6278;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
}
.verifyButton {
  width: 100%;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  background: #009ef7;
  border-radius: 7px;
  padding-top: 10px;
  padding-bottom: 10px;
}
