body {
  /* background: #fcfcfc; */
}

:root {
  --softgray: #b5b5c3;
  --blue: #009ef7;
  --dark-blue: #1e1e2d;
  --black: #000000;
  --white: #ffffff;
  --hardgray: #7e8299;
  --bggray: #f9f9f9;
  --colorgray: #969aa8;
  --lightbluebg: #ebf4fa;
}

.productsPage {
  display: flex;
  font-family: "Poppins", sans-serif;
}

.productsMain {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ebecf1;
  width: 100%;
 


}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-5%); 
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.productsMain{
  background-color: #ebecf1 !important;
}

.productsMain .lofazFooter {
  margin-top: 60px;
}

.productsMainTop {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  width: 100%;
  height: 100%;
}

.productsMainSec {
  padding: 15px;
  background-color: white;
  /* margin-left: 30px;
  margin-right: 20px; */
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  opacity: 0; /* Initially hidden */
  animation: slideIn 0.5s forwards ease-out; /* Animation definition */

}

.productsList input {
  border: none;
  background: #f9f9f9;
}

.productsList {
  color: #7e8299 !important;
  max-width: 100%;
}

/* .productsList .listComponentOrder {
  display: flex;
  justify-content: space-between;
  font-family: "Poppins", sans-serif;
} */

.productsList .listComponentOrder {
  gap: 35px;
}

.productsList .orderComponent {
  width: 25%;
  text-align: left;
}

.productsList .checkBoxComponent {
  width: 50px;
}

.productNameComponent img {
  border-radius: 5px;
}

.productNameComponent {
  display: flex;
  gap: 20px;
  color: black;
  font-weight: 600;
}

.productPriceComponent {
  display: flex;
  gap: 10px;
}

.productsMainSecButton {
  display: flex;
  justify-content: space-between;
}

.omTopRight {
  gap: 10px;
}

.filter {
  display: flex;
  gap: 10px;
  font-size: 14px;
  color: #7e8299;
}

.filterTitle {
  margin-top: 15px;
  font-weight: 600;
}
/* 
.addProductButton button {
  border: none !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  background: #009ef7 !important;
  color: white !important;
  border-radius: 5px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
} */

.addProductButton:hover {
  cursor: pointer;
}

.filter:hover {
  display: flex;
  gap: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #009ef7;
}

.table-responsive {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.table-responsive th {
  text-align: start;
  color: var(--softgray);
  line-height: 21px;
  font-size: 14px;
  font-weight: 600;
}



.table-responsive .catalogName {
  text-align: start;
  color: var(--hardgray);
}

.table-responsive .productPrices {
  text-align: start;
  color: var(--hardgray);
}

.table-responsive .productDate {
  text-align: start;
  color: var(--hardgray);
}

@media screen and (min-width: 850px) and (max-width: 1200px) {
  .productsList {
    overflow: scroll;
    position: relative;
  }
}

@media screen and (min-width: 200px) and (max-width: 850px) {
  .productsMainSecButton {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .productsMainSecButton .omTopRight {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .productsList {
    overflow: scroll;
    position: relative;
  }
}

.filter_dropdown {
  position: absolute;
  inset: 0px auto auto 0px;
  transform: translate(0px, 40px) !important;
  border: none;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.1);
  outline: none !important;

}

.search-container {
  position: relative;
  width: 100%; /* Adjust width as needed */
}

.search-input {
  width: 100%;
  padding: 10px 30px 10px 40px; /* Space for the icon and some padding */
  border: 1px solid #ccc; /* Border style */
  border-radius: 4px; /* Rounded corners */
}

.search-icon {
  position: absolute;
  top: 50%;
  left: 10px; /* Space from the left edge */
  transform: translateY(-50%); /* Vertically center the icon */
  color: #888; /* Icon color */
}





/* @media screen and (min-width: 1300px) and (max-width: 1450px) {
  .orderComponent {
    font-size: 13px;
  }
  .listHeaderElement {
    text-align: left;
  }
  .listHeader {
    display: flex;
    justify-content: space-around;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1300px) {
  .orderComponent {
    font-size: 10px;
  }
  .listHeader {
    display: flex;
    justify-content: space-around;
  }
  .listHeaderElement {
    text-align: left;
  }
} */