.addProductPage {
  display: flex;
  /* font-family: "Poppins", sans-serif; */
  font-family: "Poppins", sans-serif;
}

.addProductPageMain {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  background: #ebecf1;
}

.addProductPageMain .ordersHeader {
  background: white;
  top: 0;
  z-index: 99;
  position: sticky;
}

.addProductPageMainTop {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.addProductSection {
  display: flex;
  margin-left: 30px;
  gap: 35px;
  margin-right: 30px;
  width: 95%;
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .sidebar {
    display: none;
  }
}

@media screen and (min-width: 200px) and (max-width: 1000px) {
  .sidebar {
    display: none;
  }
  .addProductSection {
    display: flex;
    flex-direction: column;
  }

  .navigationBox {
    position: relative !important;
    width: 100% !important;
  }

  .ordersHeader {
    position: relative !important;
  }

  .addProductForm {
    margin-left: 0 !important;
    width: 100% !important;
  }
}
